<template>
  <div class="row" :style="popStyle">
    <div class="col-12">
      <c-card title="위험도" class="cardClassDetailForm" :height="(contentHeight - 70) + 'px'">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn v-if="pageEditable" label="적용" icon="check" @btnClicked="accept" />
            </q-btn-group>
          </template>
        <template slot="card-detail">
          <div class="col-12">
            <c-text
              :disabled="true"
              label="Selected"
              name="selectSeriesText"
              v-model="selectSeriesText">
            </c-text>
          </div>
          <div class="col-3">
            <q-toolbar class="bg-primary text-white">
              <q-toolbar-title>차트 Tooltip</q-toolbar-title>
            </q-toolbar>
            <q-list bordered padding>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-deep-orange">
                    <q-icon name="crop_square" />
                    빈도
                  </q-item-label>
                  <q-item-label caption>
                    {{frequencyData}}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-deep-orange">
                    <q-icon name="crop_square" />
                    강도
                  </q-item-label>
                  <q-item-label caption v-html="$comm.convertEnter(strengtyData)">
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-deep-orange">
                    <q-icon name="crop_square" />
                    위험도
                  </q-item-label>
                  <q-item-label caption>
                    {{riskData}}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <div class="col-9">
            <apexchart 
              ref="chart"
              type="heatmap" 
              :width='chartWidth'
              :options="chartOptions" 
              :series="series"
              @dataPointSelection="dataPointSelection"
              @mouseMove="mouseMove"
              @mouseLeave="mouseLeave">
            </apexchart>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'matrix-pop',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    props: {
      type: Object,
      default: function() {
        return {
          row: [],
        }
      },
    },
    col: {
      type: Object,
      default: function() {
        return {}
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    editable: {
      type: Boolean,
      default: function() {
        return false
      },
    },
    width: {
      type: String,
      default: function() {
        return ''
      },
    },
  },
  data() {
    return {
      matrix: {
        ramMatrixId: '',  // matrix 번호
        matrixName: '',  // matrix 명
        plantCd: '',  // 사업장코드
        ramTechniqueCd: null,  // 평가기법 코드
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        frequencies: [], // 빈도
        deleteFrequencies: [], // [삭제] 빈도
        strengties: [], // 강도
        deleteStrengties: [], // [삭제] 강도
        risks: [], // 위험도
        standards: [], // 위험도 기준
        deleteStandards: [], // [삭제] 위험도 기준
      },
      chartWidth: '80%',
      chartOptions: {
        tooltip: {
          enabled: true,
          custom: () => {
            return ''
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#fff']
          },
        },
        colors: ["#3f6ad8"],
        toolbar: {
          show: false
        },
        plotOptions: {
          heatmap: {
            enableShades: false,
            colorScale: {
              ranges: [],
            },
        
          }
        },
        xaxis: {
          type: 'category',
          tooltip: {
            enabled: false,
          },
          title: {
            text: '강도'
          }
        },
        yaxis: {
          show: true,
          title: {
            text: '빈도'
          }
        }
      },
      series: [],
      gridF: [],
      gridS: [],
      selectSeriesText: '',
      selectSeries: {
        f: null,
        s: null,
        r: null,
      },
      rangeComp: {
        min: 0,
        max: 0,
      },
      frequencyData: '',
      strengtyData: '',
      riskData: '',
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    pageEditable() {
      return this.editable && this.$route.meta.editable
    },
    popStyle() {
      let width = !this.width ? '1000px' : this.width
      return `width: ${width}; max-width: ${width} !important`
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.detailUrl = selectConfig.ram.matrix.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.props.row.ramMatrixId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.matrix, _result.data);

        if (this.matrix.standards && this.matrix.standards.length > 0) {
          this.$_.forEach(this.matrix.standards, item => {
            this.$set(item, 'checked', false)
            this.$set(item, 'model', {
              min: item.min,
              max: item.max,
            })
          })
          this.$forceUpdate();
        }
        /**
         * risk를 가지고 있는지 판단
         * 있을 시에 처음 데이터 가져와서 그릴때 참조
         * 
         * 그 후에 빈도, 강도를 변경하여 새롭게 적용을 할 시에는 haveRisk의 값은 false 처리되어
         * 빈도와 강도에 따라 위험도가 다시 결정되게 처리
         */
        this.haveRisk = this.matrix.risks && this.matrix.risks.length > 0
        this.setChart();
      },);
    },
    /* eslint-disable no-unused-vars */
    setChart() {
      this.rangeComp.max = 0;
      let x = this.$_.map(this.matrix.strengties, 'strength');
      let y = [];
      this.chartOptions.xaxis.categories = x
      this.$_.forEach(this.matrix.frequencies, item => {
        y.push({
          name: item.frequency,
          data: this.generateData(this.matrix.strengties.length, item.frequency)
        })
      });
      this.series = y;
      let ranges = [];
      if (this.matrix.standards && this.matrix.standards.length > 0) {
        this.$_.forEach(this.matrix.standards, range => {
          ranges.push({
            from: range.min,
            to: range.max,
            name: range.riskContents,
            color: range.color,
          })
        })
      }
      this.chartOptions.plotOptions.heatmap.colorScale.ranges = ranges;
      this.chartOptions.dataLabels.formatter = (val, opts) => {
        return '위험도 : ' + opts.value;
      }
      // let _t = this;
      // this.chartOptions.tooltip = {
      //   enabled: true,
      //   // followCursor: false,
      //   // intersect: false,
      //   custom: function({series, seriesIndex, dataPointIndex, w}) {
      //     let tip = '<div class="q-list q-pa-none q-list--bordered q-list--separator rounded-borders" style="min-width: 350px;">'
      //       + ' <div class="q-item q-pa-none q-item-type row no-wrap q-item--clickable q-link cursor-pointer q-focusable q-hoverable" tabindex="0">'
      //       + '   <div class="q-focus-helper" tabindex="-1"></div>'
      //       + '   <div class="q-item__section column q-item__section--main justify-center" style="background: #FF9800; color: #FFFFFF; max-width: 100px; text-align: center;" >'
      //       + '     <div class="q-item__label ellipsis">빈도설명</div>'
      //       + '   </div>'
      //       + '   <div class="q-item__section column q-item__section--main justify-center">'
      //       + '     <div class="q-item__label ellipsis">'
      //       + (_t.matrix.frequencies[seriesIndex] ? _t.matrix.frequencies[seriesIndex].frequencyContents : '')
      //       + '     </div>'
      //       + '   </div>'
      //       + ' </div>'
      //       + ' <div class="q-item q-pa-none q-item-type row no-wrap q-item--clickable q-link cursor-pointer q-focusable q-hoverable" tabindex="0">'
      //       + '   <div class="q-focus-helper" tabindex="-1"></div>'
      //       + '   <div class="q-item__section column q-item__section--main justify-center"  style="background: #FF9800; color: #FFFFFF; max-width: 100px; text-align: center;" >'
      //       + '     <div class="q-item__label ellipsis">강도설명</div>'
      //       + '   </div>'
      //       + '   <div class="q-item__section column q-item__section--main justify-center">'
      //       + '     <div class="q-item__label ellipsis" style="white-space:pre-line;">'
      //       + (_t.matrix.strengties[dataPointIndex] ? _t.matrix.strengties[dataPointIndex].strengthContents : '')
      //       + '     </div>'
      //       + '   </div>'
      //       + ' </div>'
      //       + '</div>'
      //     return tip;
      //   }
      // };
      setTimeout(() => {
        this.chartWidth = '95%';
      }, 300);
      this.$refs['chart'].refresh();
    },
    generateData(count, fData) {
      let i = 0;
      let series = [];
      while (i < count) {
        let data = this.matrix.strengties[i];
        let risk = this.$_.find(this.matrix.risks, { frequency: fData, strength: data.strength })
        let x = (i + 1).toString();
        let y = 0;
        
        if (this.haveRisk) {
          y = Number(risk.risk)
          // 초반에 저장된 기준으로 matrix를 구성하기 위한 설정
          this.$_.forEach(this.matrix.risks, item => {
            this.rangeComp.max = Number(item.risk) > Number(this.rangeComp.max) ? Number(item.risk) : Number(this.rangeComp.max)
          })
        } else {
          y = !isNaN(data.strength) && !isNaN(fData) ? Number(data.strength) * Number(fData) : 0
          this.rangeComp.max = y > this.rangeComp.max ? y : this.rangeComp.max
        }

        series.push({
          x: x,
          y: y
        });
        i++;
      }
      return series;
    },
    dataPointSelection(event, chartContext, config) {
      let data = this.series[config.seriesIndex].data[config.dataPointIndex];
      this.selectSeriesText = '빈도 : ' + this.matrix.frequencies[config.seriesIndex].frequency
        + ' / 강도 : ' + this.matrix.strengties[config.dataPointIndex].strength
        + ' / 위험도 : ' + data.y;
      // let color = this.$_.find(this.ranges, item => {
      //   return item.col1.min <= data.y && data.y <= item.col1.max
      // })
      let risk = this.$_.find(this.matrix.risks, { 
        frequency: this.matrix.frequencies[config.seriesIndex].frequency ,
        strength: this.matrix.strengties[config.dataPointIndex].strength,
      })
      this.selectSeries = {
        f: this.matrix.frequencies[config.seriesIndex].frequency,
        s: this.matrix.strengties[config.dataPointIndex].strength,
        r: data.y,
        ramMatrixRiskId: risk.ramMatrixRiskId
        // color: color.color,
      };
    },
    mouseMove(event, chartContext, config) {
      if (config.dataPointIndex > -1 && config.seriesIndex > -1) {
        this.frequencyData = (this.matrix.frequencies[config.seriesIndex] ? this.matrix.frequencies[config.seriesIndex].frequencyContents : '')
        this.strengtyData = (this.matrix.strengties[config.dataPointIndex] ? this.matrix.strengties[config.dataPointIndex].strengthContents : '')
        this.riskData = config.config.series[config.seriesIndex].data[config.dataPointIndex].y
      }
    },
    mouseLeave(event, chartContext, config) {
      this.frequencyData = ''
      this.strengtyData = ''
      this.riskData = ''
    },
    accept() {
      this.$emit('callback', this.selectSeries, this.selectSeries.color);
    },
  }
};
</script>
<style lang="sass">
.range-input
  div > div
    height: 30px !important
  input
    padding: 0px !important
</style>